body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.summary-box {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(-10px);
  opacity: 0;
}

.summary-box.block {
  opacity: 1;
  transform: translateY(0);
}
.button {
  width: 175px;
  display: inline-flex; /* Ensures the button only takes up the space required by its content */
  align-items: center;
  padding: 0.5rem 1rem; /* Adds padding without affecting the button's width */
  background-color: #3b82f6; /* Blue background */
  color: #ffffff; /* White text */
  font-weight: 600; /* Semibold */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  text-decoration: none; /* Removes underline from the link */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition effects */
  cursor: pointer; /* Changes cursor to pointer on hover */
}

.button:hover {
  background-color: #2563eb; /* Darker blue on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5); /* Adds a focus ring */
}

.button-icon {
  margin-left: 0.5rem; /* Adds space between text and icon */
}
